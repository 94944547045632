import { ConversationItems } from "@trunk-tools/txt-shared";
import { UserQuestionConversationItem } from "../UserQuestionConverastionItem.component";
import { useMe } from "dataHooks";
import {
  ConversationItemMessage,
  MarkdownFormatter,
  ShortenedTileList,
  TileList,
} from "@trunk-tools/ui";
import ttMessageAvatarLogo from "public/tt_message_avatar_logo.svg";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { UsefulnessVote } from "../../UsefulnessVote.component";
import { DocumentTile } from "../../DocumentTile.component";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { QuestionThinkingConversationItem } from "../QuestionThinkingConversationItem.component";
import { AnswerErrorConversationItem } from "../AnswerErrorConversationItem.component";
import { useProjectConversationQuestion } from "@/dataHooks/conversations.dataHook";
import { Suspense } from "react";
import { cleanSourcesFromAnswerText } from "@/utils/answerTextCleaning";
import { showToast } from "@/components/Toasts/ToastService";

type AnswerItemProps = {
  indexInConversationItems: number;
  question: Extract<ConversationItems, { type: "question" }>["question"];
};

const AnswerItem = ({
  question,
  indexInConversationItems,
}: AnswerItemProps) => {
  const { regenerateResponse } = useProjectConversationQuestion();
  const idNavigate = useIdNavigate();

  switch (question.state) {
    case "ANSWERED": {
      const answerText = cleanSourcesFromAnswerText(question.answer_text);

      return (
        <ConversationItemMessage
          variant="brandNoBackground"
          avatarProps={{
            variant: "v2yellowResponsive",
            img: ttMessageAvatarLogo,
            name: "TrunkText",
          }}
          isLastItem
          collapsible
          highlightOnHoverWhileClosed
          hideHeader
          headerContent={<p className="uppercase">response</p>}
          collapsedHeaderContent={<p className="uppercase">view response</p>}
        >
          <div className="flex flex-col gap-y-6">
            <MarkdownFormatter text={answerText} />
            {/** The height here helps make sure the UI doesn't flicker and update when the content below is shown on desktop */}
            <div className={`w-full desktop:max-h-full desktop:h-[50px]`}>
              <div
                className={`flex flex-col-reverse gap-y-6 gap-x-2 items-center justify-between w-full desktop:flex desktop:flex-row`}
              >
                <div
                  className="w-full desktop:w-fit"
                  data-pendo-id="copy-response-to-clipboard"
                >
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        `Question: ${question.question_text}\n\nAnswer: ${answerText}`,
                      );
                      showToast({
                        type: "success",
                        message:
                          "Response successfully copied to clipboard. Paste it in a text message or email to share with a colleague.",
                      });
                    }}
                    startIcon={<ContentCopyIcon />}
                    className="tracking-normal"
                  >
                    copy response to clipboard
                  </Button>
                </div>
                <div className="flex flex-row gap-x-2 items-center justify-between">
                  <div className="uppercase text-xs">
                    did this response help you find your answer?
                  </div>
                  <UsefulnessVote
                    itemId={question.id}
                    itemType={"QUESTION_ANSWER"}
                  />
                </div>
              </div>
            </div>
            {question.question_documents.length > 0 && (
              <div className="flex flex-col gap-y-2">
                <div className="text-xs uppercase">Response Sources</div>
                <TileList
                  tiles={question.question_documents.map((qd) => (
                    <DocumentTile
                      showSubContent={true}
                      questionId={question.id}
                      documentId={qd.document.id}
                      itemId={qd.id}
                      itemType="QUESTION_ANSWER_DOCUMENT"
                      onClick={() => {
                        idNavigate(
                          `/documents/${qd.document.id}?questionId=${question.id}&conversationId=${question.conversation_id}`,
                        );
                      }}
                    />
                  ))}
                />
              </div>
            )}
            {question.question_search_results.length > 0 && (
              <div className="flex flex-col gap-y-2 mt-4">
                <div className="text-xs uppercase">related documents</div>
                <Suspense
                  fallback={
                    <div className="text-brand uppercase p-3">
                      loading docs...
                    </div>
                  }
                >
                  <ShortenedTileList
                    tiles={question.question_search_results.map((sr) => (
                      <DocumentTile
                        key={sr.id}
                        showSubContent={true}
                        questionId={question.id}
                        documentId={sr.document.id}
                        itemId={sr.id}
                        itemType="QUESTION_SEARCH_RESULT_DOCUMENT"
                        onClick={() => {
                          idNavigate(
                            `/documents/${sr.document.id}?questionId=${question.id}&conversationId=${question.conversation_id}`,
                          );
                        }}
                        forceFullWidth={true}
                      />
                    ))}
                    maxTilesToDisplay={0}
                    popupTitle="RELATED DOCUMENTS"
                    viewButtonText={`VIEW ALL ${question.question_search_results.length} RELATED DOCUMENTS`}
                    viewButtonVariant="text"
                    textColor="brand"
                    pendoTrackingInformation={{
                      viewTilesButtonId: "view-related-documents",
                    }}
                  />
                </Suspense>
              </div>
            )}
          </div>
        </ConversationItemMessage>
      );
    }
    case "CREATED":
    case "THINKING":
      return (
        <QuestionThinkingConversationItem index={indexInConversationItems} />
      );
    case "ERRORED":
    case "FAILED_TO_ANSWER":
      return (
        <AnswerErrorConversationItem>
          <div className="flex flex-col gap-y-5 font-poppins text-lg tracking-normal">
            Sorry, Trunk Text encountered an error.
            <div>
              <Button
                color="secondary"
                startIcon={<RefreshIcon />}
                onClick={() =>
                  regenerateResponse({
                    conversationId: question.conversation_id,
                    questionId: question.id,
                  })
                }
              >
                Regenerate Response
              </Button>
            </div>
          </div>
        </AnswerErrorConversationItem>
      );
    default:
      // This will be a compile-time error if we forget to handle a state
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = question.state;
  }
};

export type CondensedQuestionAndAnswerProps = {
  indexInConversationItems: number;
  conversationItemsLength: number;
  showLatestQuestionAnswer: boolean;
  isLatestQuestionAnswerRendered: boolean;
  item: Extract<ConversationItems, { type: "question" }>;
};

export const CondensedQuestionAndAnswer = ({
  conversationItemsLength,
  indexInConversationItems,
  isLatestQuestionAnswerRendered,
  showLatestQuestionAnswer,
  item,
}: CondensedQuestionAndAnswerProps) => {
  const { me, meInitials } = useMe();
  const isLastItem = indexInConversationItems === conversationItemsLength - 1;

  let showAnswer = false;
  let isAnswerRendered = false;
  if (isLastItem) {
    showAnswer = showLatestQuestionAnswer;
    isAnswerRendered = isLatestQuestionAnswerRendered;
  }

  return (
    <div key={item.question.id}>
      <UserQuestionConversationItem
        initials={meInitials}
        name={`${me.first_name} ${me.last_name}`}
      >
        <MarkdownFormatter text={item.question.question_text} />
      </UserQuestionConversationItem>

      {(!isLastItem || showAnswer) && (
        <div
          className={`opacity-0 ${
            !isLastItem || isAnswerRendered
              ? "transition-all ease-in duration-800 opacity-100"
              : ""
          }`}
        >
          <AnswerItem
            question={item.question}
            indexInConversationItems={indexInConversationItems}
          />
        </div>
      )}
    </div>
  );
};
