import { useCreateUACAccount } from "@/dataHooks/uac/create-uac-account.dataHook";
import { useWorkatoJWTLink } from "@/dataHooks/workato/workato-jwt-link.dataHook";
import {
  WorkatoConnectionPayloadType,
  WorkatoProjectResponseType,
} from "@trunk-tools/txt-shared";
import { useEffect } from "react";
import { UACAccountType } from "@trunk-tools/txt-shared/src/schemas/uac.schema";
import { showToast } from "@/components/Toasts/ToastService";

type WorkatoMessageEvent = {
  wk: boolean;
  type: "connectionStatusChange" | "heightChange"; // These are the events we get access to on Workato's iframe
  payload: {
    id: number;
    provider: string;
    connected: boolean;
    connection_lost: boolean;
    connection_lost_reason: string | null;
  };
};

interface ConnectToIntegrationProps {
  selectedConnection: WorkatoConnectionPayloadType;
  setUACAccount: (uacAccount: UACAccountType) => void;
  workatoProject: WorkatoProjectResponseType;
}

export const ConnectToIntegration: React.FC<ConnectToIntegrationProps> = ({
  selectedConnection,
  setUACAccount,
  workatoProject,
}) => {
  const { jwtLink } = useWorkatoJWTLink(selectedConnection.id);
  const { createUACAccount } = useCreateUACAccount(workatoProject.id);

  useEffect(() => {
    const handleMessageEvent = async (event: MessageEvent<string>) => {
      const data: WorkatoMessageEvent = JSON.parse(event.data);
      if (data.type !== "connectionStatusChange") return;

      if (data.payload.connected) {
        const result = await createUACAccount(selectedConnection);
        setUACAccount(result.uac_account);
      } else {
        showToast({
          type: "error",
          message: "Connection failed, please try again.",
        });
      }
    };

    window.addEventListener("message", handleMessageEvent);

    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, [jwtLink]);

  return (
    <div style={{ display: "flex", height: "100vh", flexDirection: "column" }}>
      <iframe src={jwtLink.url} style={{ flex: 1, border: "none" }} />
    </div>
  );
};
