import { createContext } from "react";
import {
  UACProject,
  UACAccountType,
} from "@trunk-tools/txt-shared/src/schemas/uac.schema";

type UACProjectsByService = {
  id: string;
  serviceLabel: string;
  uacProjects: (UACProject & {
    uac_account: UACAccountType;
  })[];
};

type BaseFilterBlockParams = {
  uuidKey?: string;
  commonAttributes?: {
    document_source_system?: string;
    start_date?: string;
    end_date?: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PrimaryAttributeValue = any;

type AddFilterBlockParams = BaseFilterBlockParams & {
  operation: "add";
  primaryAttribute: "document_types" | "document_ids";
  primaryAttributeValue: PrimaryAttributeValue;
};

type UpdateFilterBlockParams = BaseFilterBlockParams & {
  operation: "update";
  primaryAttribute?: "document_types" | "document_ids";
  primaryAttributeValue?: PrimaryAttributeValue;
};

type RemoveFilterBlockParams = BaseFilterBlockParams & {
  operation: "remove";
  primaryAttribute: "document_types" | "document_ids";
  primaryAttributeValue: PrimaryAttributeValue;
};

export type FilterBlockChangeParams =
  | AddFilterBlockParams
  | UpdateFilterBlockParams
  | RemoveFilterBlockParams;

interface CorpusFiltersContextType {
  allUACProjects: UACProject[];
  allUACProjectsByService: UACProjectsByService[];
  availableUACProjectsByService: UACProjectsByService[];
  onFilterBlockChange: (params: FilterBlockChangeParams) => void;
  onFilterBlockDelete: (params: { uuidKey: string }) => void;
}

export const CorpusFiltersContext = createContext<CorpusFiltersContextType>({
  allUACProjects: [],
  allUACProjectsByService: [],
  availableUACProjectsByService: [],
  onFilterBlockChange: () => void 0,
  onFilterBlockDelete: () => void 0,
});
