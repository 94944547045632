import { DocumentTypes, ExternalDocumentType } from "@trunk-tools/txt-shared";

export const CORPUS_FILTER_BLOCK_CLASSNAMES_ROOT =
  "bg-[#0F151A7A] border border-[#0D1217] p-5 rounded";

type DocumentTypeTreeRoot = {
  id: ExternalDocumentType;
  internalId: keyof typeof DocumentTypes;
  label: string;
  type: string;
};

export const CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS: DocumentTypeTreeRoot[] = [
  {
    id: ExternalDocumentType.rfi,
    internalId: DocumentTypes.RFI,
    label: "RFIs",
    type: "folder",
  },
  {
    id: ExternalDocumentType.submittal,
    internalId: DocumentTypes.Submittal,
    label: "Submittals",
    type: "folder",
  },
  {
    id: ExternalDocumentType.drawing,
    internalId: DocumentTypes.Drawing,
    label: "Drawings",
    type: "folder",
  },
  {
    id: ExternalDocumentType.form,
    internalId: DocumentTypes.Form,
    label: "Forms",
    type: "folder",
  },
  {
    id: ExternalDocumentType.issue,
    internalId: DocumentTypes.Issue,
    label: "Issues",
    type: "folder",
  },
  {
    id: ExternalDocumentType.meeting_notes,
    internalId: DocumentTypes.MeetingNotes,
    label: "Meetings",
    type: "folder",
  },
];
