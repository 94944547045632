import {
  WorkatoProjectResponseType,
  WorkatoCreatedFolderType,
} from "@trunk-tools/txt-shared";
import { UACAccountType } from "@trunk-tools/txt-shared/src/schemas/uac.schema";
import { SelectUACProject } from "./SelectUACProject.current.page";
import { useAvailableSharepointDrives } from "@/dataHooks/workato/sharepoint/available-sharepoint-drives.dataHook";
import { Form, Text } from "@trunk-tools/ui";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { api } from "@/dataHooks/helpers/APIClient";
import { useCurrentProject } from "dataHooks";
import { useState } from "react";

interface SelectUACProjectProps {
  uacAccount: UACAccountType;
  selectedWorkspaceProject: WorkatoProjectResponseType;
  selectedWorkspaceFolder: WorkatoCreatedFolderType;
}

export const SetupUACProject: React.FC<SelectUACProjectProps> = ({
  uacAccount,
  selectedWorkspaceProject,
  selectedWorkspaceFolder,
}) => {
  const { source_system } = uacAccount;

  if (source_system !== "SharePoint_Online") {
    return (
      <SelectUACProject
        uacAccount={uacAccount}
        selectedWorkspaceProject={selectedWorkspaceProject}
        selectedWorkspaceFolder={selectedWorkspaceFolder}
      />
    );
  }

  return (
    <SetupSharepointDrive
      selectedWorkspaceProject={selectedWorkspaceProject}
      uacAccount={uacAccount}
      selectedWorkspaceFolder={selectedWorkspaceFolder}
    />
  );
};

export const SetupSharepointDrive: React.FC<SelectUACProjectProps> = (
  props,
) => {
  const [hostname, setHostname] = useState<string | null>(null);
  const [path, setPath] = useState<string | null>(null);

  if (hostname && path) {
    return <SelectSharepointDrive {...props} hostname={hostname} path={path} />;
  }

  return (
    <Form.Provider
      validators={{
        hostname: Form.validators.required,
        path: Form.validators.required,
      }}
      initialValues={{
        hostname: "",
        path: "",
      }}
      onSubmit={async (formFields: Record<string, string>) => {
        setHostname(formFields.hostname);
        setPath(formFields.path);
      }}
    >
      <>
        <Text.Header className="mb-3">
          Enter Your Sharepoint Site Details
        </Text.Header>
        <div className="mb-5 w-50">
          <Form.Fields.Text name="hostname" />
        </div>
        <div className="mb-5 w-50">
          <Form.Fields.Text name="path" />
        </div>
      </>
    </Form.Provider>
  );
};

export const SelectSharepointDrive: React.FC<
  SelectUACProjectProps & { hostname: string; path: string }
> = ({
  selectedWorkspaceProject,
  uacAccount,
  selectedWorkspaceFolder,
  hostname,
  path,
}) => {
  const idNavigate = useIdNavigate();
  const { currentProject } = useCurrentProject();
  const { sharepointDrivesResponse, isLoading } = useAvailableSharepointDrives(
    selectedWorkspaceProject.id,
    hostname,
    path,
  );

  return (
    <Form.Provider
      validators={{
        selectedSharepointDrive: Form.validators.required,
      }}
      initialValues={{
        selectedSharepointDrive: "",
      }}
      onSubmit={async (formFields: Record<string, string>) => {
        const selectedDrive = sharepointDrivesResponse.drives.find(
          (project) => project.id === formFields.selectedSharepointDrive,
        );

        if (selectedDrive && uacAccount) {
          const { uacProject } = await api.createUACProject({
            projectId: currentProject.id,
            name: sharepointDrivesResponse.site.name,
            uacId: sharepointDrivesResponse.site.id,
            sourceId: sharepointDrivesResponse.site.id,
            uacAccountId: uacAccount.id,
            workatoProjectId: selectedWorkspaceProject.id,
            workatoFolderId: selectedWorkspaceFolder.id,
          });

          await api.initializeUACProject({
            projectId: currentProject.id,
            uacProjectId: uacProject.id,
            workatoProjectId: selectedWorkspaceProject.id,
            uacAccountId: uacAccount.id,
            workatoFolderId: selectedWorkspaceFolder.id,
            sharepointConfig: {
              driveId: selectedDrive.id,
            },
          });
          idNavigate(`/integrations/${uacProject.id}`);
        }
      }}
    >
      <>
        <Text.Header className="mb-3">Select Your Document Library</Text.Header>
        <div className="mb-5">
          <Form.Fields.SearchableSelect
            name="selectedSharepointDrive"
            items={sharepointDrivesResponse.drives.map(
              (uacProject: { name: string; id: string }) => ({
                label: uacProject.name,
                value: uacProject.id,
              }),
            )}
            isAsync={true}
            isLoading={isLoading}
          />
        </div>
      </>
    </Form.Provider>
  );
};
