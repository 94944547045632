import { api } from "@/dataHooks/helpers/APIClient";
import { useCurrentProject } from "@/dataHooks/project.datahook";
import { useGetUACPotentialProjects } from "@/dataHooks/uac/get-uac-potential-projects.dataHook";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import {
  WorkatoCreatedFolderType,
  WorkatoProjectResponseType,
} from "@trunk-tools/txt-shared";
import { UACAccountType } from "@trunk-tools/txt-shared/src/schemas/uac.schema";
import { Form, Text } from "@trunk-tools/ui";

interface SelectUACProjectProps {
  uacAccount: UACAccountType;
  selectedWorkspaceProject: WorkatoProjectResponseType;
  selectedWorkspaceFolder: WorkatoCreatedFolderType;
}

export const SelectUACProject: React.FC<SelectUACProjectProps> = ({
  uacAccount,
  selectedWorkspaceProject,
  selectedWorkspaceFolder,
}) => {
  const {
    isLoadingPotentialProjects,
    potentialProjects,
    setPotentialProjectsSearch,
  } = useGetUACPotentialProjects(uacAccount);
  const idNavigate = useIdNavigate();
  const { currentProject } = useCurrentProject();

  return (
    <Form.Provider
      validators={{
        selectedUACProject: Form.validators.required,
      }}
      initialValues={{
        selectedUACProject: "",
      }}
      onSubmit={async (formFields: Record<string, string>) => {
        const selectedProject = potentialProjects.find(
          (project) => project.id === formFields.selectedUACProject,
        );
        if (selectedProject && uacAccount) {
          const { uacProject } = await api.initializeUACProject({
            projectId: currentProject.id,
            uacProjectId: selectedProject.id,
            workatoProjectId: selectedWorkspaceProject.id,
            uacAccountId: uacAccount.id,
            workatoFolderId: selectedWorkspaceFolder.id,
          });
          idNavigate(`/integrations/${uacProject.id}`);
        }
      }}
    >
      <>
        <Text.Header className="mb-3">Select A Project To Sync</Text.Header>
        <div className="mb-5">
          <Form.Fields.SearchableSelect
            name="selectedUACProject"
            items={potentialProjects.map(
              (uacProject: { name: string; id: string }) => ({
                label: uacProject.name,
                value: uacProject.id,
              }),
            )}
            isAsync={true}
            onSearch={setPotentialProjectsSearch}
            isLoading={isLoadingPotentialProjects}
          />
        </div>
      </>
    </Form.Provider>
  );
};
