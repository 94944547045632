import useSWR from "swr";
import { api } from "../../helpers/APIClient";
import { useCurrentProject } from "../../project.datahook";

export const useAvailableSharepointDrives = (
  workatoProjectId: string,
  hostname: string,
  path: string,
) => {
  const { currentProject } = useCurrentProject();

  const { data: sharepointDrivesResponse, isLoading } = useSWR(
    ["sharepointDrives", workatoProjectId],
    () =>
      api.getSharepointDrives({
        trunkToolsProjectId: currentProject.id,
        workatoProjectId: workatoProjectId,
        hostname,
        path,
      }),
    {
      suspense: true,
    },
  );

  return {
    sharepointDrivesResponse,
    isLoading,
  };
};
