// MUI
import { Typography } from "@mui/material";

// Types
import { LocalCorpusFilter } from "../../CorpusFilters.types";
import { SingleFilterRefinement } from "../components/SingleFilterRefinement.component";

export const CorpusFilterRefinementPanel = ({
  corpusFilter,
}: {
  corpusFilter: Partial<LocalCorpusFilter>;
}) => {
  const corpusFilterFilters = corpusFilter.filters || [];
  /**
   * Handle filters without source systems, which means the filters
   * apply to all integrations.
   */
  const filtersWithoutSystems = corpusFilterFilters.filter(
    (filter) => !filter.document_source_system,
  );
  const filtersWithoutSystemsComponent = (
    <div className="flex flex-col gap-2">
      <Typography variant="h6">Selected Filters</Typography>
      {filtersWithoutSystems.length === 0 && (
        <Typography variant="body1" className="text-gray-500">
          No filters selected
        </Typography>
      )}
      <div className="flex flex-col gap-6">
        {filtersWithoutSystems.map((filter) => (
          <SingleFilterRefinement key={filter._uuidKey} filter={filter} />
        ))}
      </div>
    </div>
  );

  /**
   * Handle filters with source systems, which means the filters
   * apply to a specific integration.
   */
  const filtersWithSystems = corpusFilterFilters.filter((filter) =>
    Boolean(filter.document_source_system),
  );
  const groupedBySystem = filtersWithSystems.reduce((acc, filter) => {
    const key = filter.document_source_system!;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(filter);
    return acc;
  }, {} as Record<string, typeof corpusFilterFilters>);

  const filtersWithSystemsComponent = Object.entries(groupedBySystem).map(
    ([system, filters]) => {
      return (
        <div>
          <Typography variant="body1">{system}</Typography>
          {filters.map((filter) => (
            <SingleFilterRefinement key={filter._uuidKey} filter={filter} />
          ))}
        </div>
      );
    },
  );

  return (
    <div>
      {filtersWithoutSystemsComponent}
      {filtersWithSystemsComponent}
    </div>
  );
};
