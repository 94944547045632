import { useState } from "react";
import { Button, Popover, ListSubheader } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { AccessTime, ArrowDropDown } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";

const getCommonDateFilterProps = ({
  placeholder,
}: {
  placeholder: string;
}): DatePickerProps<Dayjs, false> => {
  return {
    sx: { maxWidth: 170 },
    slotProps: {
      textField: {
        size: "small",
        fullWidth: true,
        placeholder,
      },
    },
  };
};

export const DateFilterSelector = ({
  onChange,
  initialStartDate,
  initialEndDate,
}: {
  initialStartDate?: string;
  initialEndDate?: string;
  onChange: ({
    startDate,
    endDate,
  }: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    initialStartDate ? dayjs(initialStartDate) : null,
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    initialEndDate ? dayjs(initialEndDate) : null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const hasAnInitialDate = initialStartDate || initialEndDate;

  return (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={handleClick}
        startIcon={<AccessTime fontSize="small" />}
        endIcon={<ArrowDropDown fontSize="small" />}
        sx={{
          "& .MuiButton-endIcon": {
            marginLeft: "4px",
          },
        }}
      >
        {hasAnInitialDate
          ? `${
              startDate?.isValid() ? startDate.format("MM/DD/YYYY") : "Any"
            } - ${endDate?.isValid() ? endDate.format("MM/DD/YYYY") : "Any"}`
          : "Any"}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transitionDuration={0}
      >
        <ListSubheader>Date Last Modified</ListSubheader>
        <div className="px-4 pb-4 flex flex-row gap-3">
          <DatePicker
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
              onChange({ startDate: newValue, endDate });
            }}
            {...getCommonDateFilterProps({ placeholder: "Start Date" })}
          />
          <DatePicker
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
              onChange({ startDate, endDate: newValue });
            }}
            {...getCommonDateFilterProps({ placeholder: "End Date" })}
          />
        </div>
      </Popover>
    </>
  );
};
