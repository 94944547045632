import { useContext, useMemo, useState } from "react";

// Components
import { TextInput } from "@trunk-tools/ui";
import { RichTreeView } from "@mui/x-tree-view";

// Contexts
import { CorpusFiltersContext } from "../CorpusFilters.context";

export const DataAndDocumentsTree = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const { allUACProjectsByService } = useContext(CorpusFiltersContext);

  const treeItems = useMemo(() => {
    if (!allUACProjectsByService) return [];
    const treeItems = allUACProjectsByService.map((uacProjectsByService) => ({
      id: uacProjectsByService.id,
      label: "All Data & Documents",
      type: "folder",
      children: uacProjectsByService.uacProjects.map((uacProject) => ({
        id: uacProject.id,
        label: `${uacProject.uac_account.source_system}: ${uacProject.name}`,
        type: "file",
      })),
    }));

    const expandedItems: string[] = [];
    treeItems.forEach((item) => {
      expandedItems.push(item.id);
      item.children?.forEach((child) => {
        expandedItems.push(child.id);
      });
    });

    setExpandedItems(expandedItems);

    return treeItems;
  }, [allUACProjectsByService]);

  return (
    <>
      <TextInput
        rounded
        name="corpus-filter-data-and-documents-search"
        placeholder="Search..."
        value={searchTerm}
        onChange={(nextValue) => {
          setSearchTerm(nextValue);
        }}
      />
      <RichTreeView
        className="mt-4"
        items={treeItems}
        selectedItems={selectedItems}
        defaultExpandedItems={expandedItems}
        onExpandedItemsChange={(_event, itemIds) => {
          setExpandedItems(itemIds);
        }}
        onSelectedItemsChange={(_event, itemIds) => {
          setSelectedItems(itemIds);
        }}
        multiSelect
        checkboxSelection
      />
    </>
  );
};
