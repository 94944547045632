import { useContext, useEffect, useState } from "react";

// Components
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
} from "@mui/material";

// Contexts
import { CorpusFiltersContext } from "../CorpusFilters.context";

// Types
import { LocalCorpusFilterFilter } from "../CorpusFilters.types";

// Hooks
import { useSearchProjectDocuments } from "dataHooks";

// Constants
import { DocumentTypes } from "@trunk-tools/txt-shared";

export const DocumentsList = ({
  filters,
  searchTerm,
  type,
}: {
  filters: LocalCorpusFilterFilter[];
  searchTerm?: string;
  type?: DocumentTypes;
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const { onFilterBlockChange } = useContext(CorpusFiltersContext);

  const { data, isLoading } = useSearchProjectDocuments({
    searchTerm,
    skip: 0,
    take: 50,
    type,
  });

  useEffect(() => {
    const newSelectedItems: string[] = [];
    filters.forEach((filter) => {
      if (filter.document_ids?.length) {
        newSelectedItems.push(...filter.document_ids);
      }
    });

    setSelectedItems(newSelectedItems);
  }, [filters]);

  const { documents = [] } = data || {};

  return (
    <>
      {!isLoading && documents.length === 0 && (
        <Typography variant="body1" className="text-gray-500">
          {searchTerm ? `No Results for "${searchTerm}"` : "No Documents Found"}
        </Typography>
      )}
      <FormGroup className="flex flex-col gap-3">
        {documents.map((document) => {
          const documentId = document.id;
          const isSelected = selectedItems.includes(documentId);
          return (
            <FormControlLabel
              key={documentId}
              control={<Checkbox checked={isSelected} />}
              label={
                <div className="flex flex-col gap-0">
                  <Typography variant="body1">{document.name}</Typography>
                  <Typography variant="body2" className="text-gray-500">
                    {document.type} |{" "}
                    {document.uac_project?.name || "Unknown Source"}
                  </Typography>
                </div>
              }
              onChange={(_event, checked) => {
                if (checked) {
                  onFilterBlockChange({
                    operation: "add",
                    primaryAttribute: "document_ids",
                    primaryAttributeValue: documentId,
                  });
                } else {
                  onFilterBlockChange({
                    operation: "remove",
                    primaryAttribute: "document_ids",
                    primaryAttributeValue: documentId,
                  });
                }
              }}
            />
          );
        })}
      </FormGroup>
    </>
  );
};
