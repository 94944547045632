import { AlertColor } from "@mui/material";
import { ToastMessageMessage } from "./ToastsContainer.component";

type ToastParams = {
  message: ToastMessageMessage;
  type: AlertColor;
  closeTimeout?: number;
};

export class ToastService {
  private static callback: ((params: ToastParams) => void) | null = null;

  static setCallback(cb: (params: ToastParams) => void) {
    this.callback = cb;
  }

  static showToast = (params: ToastParams) => {
    this.callback?.(params);
  };
}

export const showToast = ToastService.showToast.bind(ToastService);
