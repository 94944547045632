// Components
import { Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DateFilterSelector } from "./DateFilterSelector.component";

// Constants
import { CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS } from "../../CorpusFilterBlock.constants";

// Contexts
import { useContext } from "react";
import { CorpusFiltersContext } from "../../CorpusFilters.context";

// Types
import { LocalCorpusFilterFilter } from "../../CorpusFilters.types";
import { cn } from "@trunk-tools/ui";
import { useDocument } from "@/dataHooks/documents.dataHook";

const RenderDocument = ({ documentId }: { documentId: string }) => {
  const { document } = useDocument({ documentId });

  return (
    <Typography variant="body2" noWrap>
      {document.name}
    </Typography>
  );
};

export const SingleFilterRefinement = ({
  filter,
}: {
  filter: LocalCorpusFilterFilter;
}) => {
  const { onFilterBlockChange, onFilterBlockDelete } =
    useContext(CorpusFiltersContext);

  let label = "";
  let isDocument = false;
  let canSetModifiedDates = false;
  if (filter.document_types?.length) {
    const documentType = filter.document_types![0];
    const foundDocumentType = CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS.find(
      (root) => root.id === documentType,
    );
    label = foundDocumentType?.label ?? documentType;
    canSetModifiedDates = true;
  }

  if (filter.folder_ids?.length) {
    label = `${filter.folder_ids[0]} folder(s)`;
    canSetModifiedDates = true;
  }

  if (filter.document_ids?.length) {
    isDocument = true;
    label = `${filter.document_ids[0]}`;
  }

  if (!label) {
    return null;
  }

  return (
    <div
      key={filter._uuidKey}
      className={cn("flex flex-row flex-wrap gap-2", {
        "flex-nowrap": !canSetModifiedDates,
        "flex-wrap": canSetModifiedDates,
      })}
    >
      <div
        className={cn("bg-[#005A9C] flex items-center px-3 py-2 rounded-sm", {
          "overflow-hidden": !canSetModifiedDates,
        })}
      >
        {isDocument && <RenderDocument documentId={filter.document_ids![0]} />}
        {!isDocument && (
          <Typography variant="body2" noWrap>
            {label}
          </Typography>
        )}
      </div>
      {canSetModifiedDates && (
        <DateFilterSelector
          initialStartDate={filter.start_date}
          initialEndDate={filter.end_date}
          onChange={({ startDate, endDate }) => {
            onFilterBlockChange({
              uuidKey: filter._uuidKey,
              operation: "update",
              commonAttributes: {
                start_date: startDate?.toISOString(),
                end_date: endDate?.toISOString(),
              },
            });
          }}
        />
      )}
      <Button
        variant="iconbutton"
        color="secondary"
        onClick={() => {
          onFilterBlockDelete({
            uuidKey: filter._uuidKey,
          });
        }}
      >
        <CloseIcon />
      </Button>
    </div>
  );
};
