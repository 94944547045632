import { forwardRef, Suspense, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import sendQuestionArrow from "public/send_question_arrow.svg";
import sendQuestionArrowBrand from "public/send_question_arrow-brand.svg";
import TextareaAutosize from "react-textarea-autosize";
import {
  useCreateConversation,
  useConversation,
  useProjectConversationQuestion,
  useSetCurrentCorpusFilterOnConversation,
} from "@/dataHooks/conversations.dataHook";
import {
  LoadingRectangle,
  LoadingCircle,
  LoadingThumbsDown,
  LoadingThumbsUp,
  LoadingTrunkTextLogo,
} from "@trunk-tools/ui";
import { IoIosArrowDown } from "react-icons/io";
import { ConversationQuestions } from "./ConversationQuestions.component";
import { useCorpusFilter, useCurrentProject } from "dataHooks";
import { CorpusFilters } from "../CorpusFilters/CorpusFilters.component";
import { FeatureFlagGate } from "../FeatureFlagGate.component";
import { CorpusFilter } from "@trunk-tools/txt-shared";

const NewConversationBlurb = () => {
  /**
   * Based on the converasation following this slack message: https://trunk-tools.slack.com/archives/C03CLQCF6J3/p1715712792165209
   * we decided to remove the Sample Questions for now. We will revisit this when we decide to build out example questions generated
   * by an LLM. The code for this component can be found in git history.
   */
  return <div></div>;
};

const CreateConversationArrow = forwardRef(
  (
    {
      questionText,
      corpusFilterId,
      onClick,
    }: {
      questionText: string;
      corpusFilterId?: string;
      onClick: () => void;
    },
    ref: React.ForwardedRef<HTMLImageElement>,
  ) => {
    const { createConversation } = useCreateConversation();
    const arrowSrcImg =
      questionText.length > 0 ? sendQuestionArrowBrand : sendQuestionArrow;

    return (
      <span
        onClick={() => {
          onClick();
          if (questionText.length > 0) {
            createConversation({
              questionText,
              corpusFilterId,
            });
          }
        }}
        data-pendo-id="create-conversation"
        ref={ref}
        className="cursor-pointer absolute right-2 top-1/2 w-6 h-6 bg-gray p-6"
        style={{
          transform: "translate(0%, -50%)",
        }}
      >
        <img
          src={arrowSrcImg}
          alt="send question"
          className="absolute top-1/2 left-1/2"
          style={{
            transform: "translate(-50%, -60%)",
          }}
        />
      </span>
    );
  },
);

const AskFollowUpQuestionArrow = forwardRef(
  (
    {
      questionText,
      conversationId,
      onClick,
    }: {
      questionText: string;
      conversationId: string;
      onClick: () => void;
    },
    ref: React.ForwardedRef<HTMLImageElement>,
  ) => {
    const { askFollowUpQuestion } = useProjectConversationQuestion();
    const { refetchConversation } = useConversation({
      conversationId,
    });
    const arrowSrcImg =
      questionText.length > 0 ? sendQuestionArrowBrand : sendQuestionArrow;

    return (
      <span
        ref={ref}
        className="cursor-pointer absolute right-2 top-1/2 w-6 h-6 bg-gray p-6"
        style={{
          transform: "translate(0%, -50%)",
        }}
        onClick={async () => {
          onClick();
          if (questionText.length > 0) {
            await askFollowUpQuestion({
              conversationId,
              questionText,
            });
            refetchConversation();
          }
        }}
        data-pendo-id="ask-follow-up-question"
      >
        <img
          src={arrowSrcImg}
          alt="send question"
          className="absolute top-1/2 left-1/2"
          style={{
            transform: "translate(-50%, -60%)",
          }}
        />
      </span>
    );
  },
);

const SuspenseBoundary = ({ children }: { children: React.ReactNode }) => {
  const { conversationId } = useParams<{ conversationId: string }>();

  const desktopSuspense = (
    <div className="w-full h-full">
      {conversationId ? (
        <div className="flex flex-col gap-y-8">
          <div className="flex flex-row gap-x-4">
            <LoadingCircle />
            <div className="w-full bg-[#444E5120] rounded flex flex-col gap-y-8 pb-8">
              <LoadingRectangle width="w-full" height="h-2" />
              <div className="w-full px-6">
                <LoadingRectangle width="w-full" height="h-5" />
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-x-4 overflow-x-hidden">
            <LoadingCircle />
            <div className="w-full flex flex-col gap-y-[2px]">
              <div className="w-full bg-[#444E5120] rounded-t flex flex-col gap-y-8 pb-8">
                <LoadingRectangle width="w-full" height="h-2" />
                <div className="w-full px-6 flex flex-col gap-y-8">
                  <LoadingRectangle width="w-20" height="h-5" />
                  <div className="pl-4">
                    <div className="flex flex-col gap-y-8">
                      {[1, 2, 3].map((n) => {
                        return (
                          <div className="flex flex-row gap-x-4" key={n}>
                            <LoadingRectangle width="w-8" height="h-8" />
                            <div className="flex flex-col gap-y-1">
                              <LoadingRectangle width="w-54" height="h-5" />
                              <LoadingRectangle width="w-9" height="h-4" />
                              <LoadingRectangle width="w-62" height="h-5" />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full bg-[#444E5120] rounded-b">
                <div className="px-6 py-8">
                  <div className="flex flex-col gap-y-2">
                    <LoadingRectangle width="w-20" height="h-5" />
                    <LoadingRectangle width="w-full" height="h-78" />
                  </div>
                  <div className="my-6">
                    <div className="flex flex-row justify-between">
                      <LoadingRectangle width="w-52" height="h-8" />
                      <div className="flex flex-row items-center gap-x-4">
                        <LoadingRectangle width="w-60" height="h-5" />
                        <div className="flex flex-row items-center gap-x-2">
                          <LoadingThumbsDown />
                          <LoadingThumbsUp />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-8">
                    {[1, 2, 3].map((n) => {
                      return (
                        <div className="flex flex-row gap-x-4" key={n}>
                          <LoadingRectangle width="w-8" height="h-8" />
                          <div className="flex flex-col gap-y-1">
                            <LoadingRectangle width="w-54" height="h-5" />
                            <LoadingRectangle width="w-9" height="h-4" />
                            <LoadingRectangle width="w-62" height="h-5" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <LoadingTrunkTextLogo />
        </div>
      )}
    </div>
  );

  const mobileSuspense = (
    <div className="w-full h-full">
      {conversationId ? (
        <div className="flex flex-col gap-y-8 px-4">
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-row gap-x-2 items-center">
              <LoadingCircle responsive />
              <LoadingRectangle width="w-15" height="h-4" />
            </div>
            <div className="w-full flex flex-col gap-y-[2px]">
              <div className="w-full bg-[#444E5120] rounded flex flex-col gap-y-4 pb-4">
                <LoadingRectangle width="w-full" height="h-2" />
                <div className="px-4">
                  <LoadingRectangle width="w-full" height="h-16" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <div className="flex flex-row gap-x-2 items-center">
              <LoadingCircle responsive />
              <LoadingRectangle width="w-15" height="h-4" />
            </div>
            <div className="w-full flex flex-col gap-y-[2px]">
              <div className="w-full bg-[#444E5120] rounded-t flex flex-col gap-y-4 pb-4">
                <LoadingRectangle width="w-full" height="h-2" />
                <div className="px-4">
                  <LoadingRectangle width="w-40" height="h-5" />
                </div>
              </div>
              <div className="w-full bg-[#444E5120] rounded-b flex flex-col gap-y-4 p-4">
                <LoadingRectangle width="w-20" height="h-5" />
                <LoadingRectangle width="w-full" height="h-80" />
                <div className="flex flex-row gap-x-6 justify-between">
                  <LoadingRectangle width="w-full" height="h-5" />
                  <div className="flex flex-row items-center gap-x-2">
                    <LoadingThumbsDown />
                    <LoadingThumbsUp />
                  </div>
                </div>
                <LoadingRectangle width="w-full" height="h-8" />
                <LoadingRectangle width="w-30" height="h-5" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <LoadingTrunkTextLogo />
        </div>
      )}
    </div>
  );

  return (
    <Suspense
      fallback={
        <div className="h-full p-2 overflow-y-auto">
          <div className="h-full flex flex-col justify-end">
            <div className="h-full hidden desktop:block overflow-y-auto">
              {desktopSuspense}
            </div>
            <div className="h-full desktop:hidden block overflow-y-auto">
              {mobileSuspense}
            </div>

            <div className="p-4 pr-0 w-full">
              <div className="w-full pr-4 desktop:pr-0 relative">
                <LoadingRectangle width="w-full" height="h-18" />
              </div>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

const ConversationInner = () => {
  const [questionText, setQuestionText] = useState("");
  const [selectedCorpusFilter, setSelectedCorpusFilter] = useState<
    Partial<CorpusFilter> | undefined
  >({
    name: "Default",
  });
  const { conversationId } = useParams<{ conversationId: string }>();
  const submitIconRef = useRef<HTMLImageElement>(null);
  const { currentProject } = useCurrentProject();
  const { conversation, refetchConversation } = useConversation({
    conversationId,
  });
  const { data: corpusFilter } = useCorpusFilter({
    corpusFilterId: conversation?.current_corpus_filter_id,
  });

  const location = useLocation();

  /**
   * Reset the selected corpus filter when navigating to a different or
   * new conversation.
   */
  useEffect(() => {
    setSelectedCorpusFilter({
      name: "Default",
    });
  }, [location.pathname]);

  useEffect(() => {
    if (corpusFilter) {
      setSelectedCorpusFilter(corpusFilter);
    }
  }, [corpusFilter]);

  const [corpusFiltersDialogOpen, setCorpusFiltersDialogOpen] = useState(false);
  const { trigger: setCurrentCorpusFilterOnConversation } =
    useSetCurrentCorpusFilterOnConversation({
      onSuccess: () => {
        refetchConversation();
      },
    });

  const onEnterPress = (e) => {
    // 13 is the enter key
    // if shift is not pressed, then submit the question
    // if shift is pressed, then operate as normal (adds a newline)
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();

      if (submitIconRef.current) {
        submitIconRef.current.click();
      }
    }
  };

  if (currentProject.project_question_status === "NOT_READY_FOR_QUESTIONS") {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center p-6">
        <div
          className="w-full desktop:w-7/12 h-fit flex flex-col gap-y-6 text-lg text-center font-poppins rounded-md px-6 pt-12 pb-13 bg-[#171B1C]"
          style={{
            boxShadow: "0px 1px 0px 0px #FFFFFF29, 0px 0px 16px 0px #F3CF7A0A",
          }}
        >
          <p>
            TrunkText is reading the data and documents for{" "}
            {currentProject.name}.
          </p>
          <p>
            We’ll notify you when this process is complete and TrunkText is
            ready for questions.
          </p>
        </div>
        <div
          className="w-full h-18 pl-4 flex items-center rounded mt-auto bg-[#2931437A]"
          style={{
            boxShadow: "0px 1px 0px 0px #FFFFFF14",
          }}
        >
          <p className="text-[#3243537A]">Ask a question...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-2 overflow-y-auto">
      <div className="h-full flex flex-col justify-end">
        {conversationId ? (
          <ConversationQuestions
            conversationId={conversationId}
            updateQuestionText={setQuestionText}
          />
        ) : (
          <NewConversationBlurb />
        )}

        <div className="p-4 w-full">
          <FeatureFlagGate
            enabled={() => (
              <>
                <button
                  className="cursor-pointer flex flex-row flex-nowrap items-center gap-2 rounded-sm bg-tt-moonlight-blue hover:bg-tt-moonlight-blue-light border border-tt-border px-4 py-3 truncate max-w-full"
                  onClick={() => setCorpusFiltersDialogOpen(true)}
                >
                  <span className="uppercase truncate">
                    Filter:{" "}
                    <span className="text-brand-dark">
                      {corpusFilter?.name ??
                        selectedCorpusFilter?.name ??
                        "Default"}
                    </span>
                  </span>
                  <IoIosArrowDown className="w-6" />
                </button>
                {corpusFiltersDialogOpen && (
                  <CorpusFilters
                    onDialogClose={() => setCorpusFiltersDialogOpen(false)}
                    onApply={({
                      corpusFilter,
                    }: {
                      corpusFilter?: CorpusFilter;
                    }) => {
                      setSelectedCorpusFilter(corpusFilter);

                      // Finally, if a filter and conversation ID exist, update the conversation
                      if (conversationId) {
                        setCurrentCorpusFilterOnConversation({
                          conversation_id: conversationId,
                          corpus_filter_id: corpusFilter?.id,
                        });
                      }

                      setTimeout(() => {
                        setCorpusFiltersDialogOpen(false);
                      }, 100);
                    }}
                    initiallySelectedCorpusFilterId={selectedCorpusFilter?.id}
                  />
                )}
              </>
            )}
            disabled={() => null}
            flagKey={(FlagKey) => FlagKey.ConversationCorpusFilters}
          />
          <div className="w-full relative">
            <TextareaAutosize
              className="w-full h-full text-base p-6 rounded tracking-normal pr-12"
              rows={1}
              style={{
                color: "#324353",
                border: "1px solid #F5BF40",
                boxShadow:
                  "0px 1px 4px 0px #40300C7A, 0px 0px 8px 0px #F9DFA17A",
              }}
              value={questionText}
              placeholder="Ask a question..."
              onChange={(e) => setQuestionText(e.target.value)}
              onKeyDown={onEnterPress}
            />
            {conversationId ? (
              <AskFollowUpQuestionArrow
                questionText={questionText}
                conversationId={conversationId}
                onClick={() => setQuestionText("")}
                ref={submitIconRef}
              />
            ) : (
              <CreateConversationArrow
                questionText={questionText}
                corpusFilterId={selectedCorpusFilter?.id}
                onClick={() => setQuestionText("")}
                ref={submitIconRef}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Conversation = () => {
  return (
    <SuspenseBoundary>
      <ConversationInner />
    </SuspenseBoundary>
  );
};
