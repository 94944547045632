import { useContext, useEffect, useState } from "react";

// Components
import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { TextInput } from "@trunk-tools/ui";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Hooks
import { useDebounceValue } from "usehooks-ts";

// Contexts
import { CorpusFiltersContext } from "../CorpusFilters.context";

// Types
import { LocalCorpusFilterFilter } from "../CorpusFilters.types";

// Constants
import { CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS } from "../CorpusFilterBlock.constants";
import { DocumentsList } from "./DocumentsList";

const DocumentClassifierTreeBranch = ({
  treeRootItem,
  selectedItems,
  filters,
  searchTerm,
  selectedUACProjectId,
}) => {
  const [expanded, setExpanded] = useState<boolean>(Boolean(searchTerm));
  const isSelected = selectedItems.includes(treeRootItem.id);

  useEffect(() => {
    if (searchTerm) {
      setExpanded(true);
    }
  }, [searchTerm]);

  const { onFilterBlockChange } = useContext(CorpusFiltersContext);
  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <button onClick={() => setExpanded((expanded) => !expanded)}>
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </button>
        <FormControlLabel
          control={<Checkbox checked={isSelected} />}
          label={treeRootItem.label}
          onChange={(_event, checked) => {
            if (checked) {
              onFilterBlockChange({
                operation: "add",
                primaryAttribute: "document_types",
                primaryAttributeValue: treeRootItem.id,
                commonAttributes: {
                  ...(selectedUACProjectId
                    ? { document_source_system: selectedUACProjectId }
                    : {}),
                },
              });
            } else {
              onFilterBlockChange({
                operation: "remove",
                primaryAttribute: "document_types",
                primaryAttributeValue: treeRootItem.id,
                commonAttributes: {
                  ...(selectedUACProjectId
                    ? { document_source_system: selectedUACProjectId }
                    : {}),
                },
              });
            }
          }}
        />
      </div>
      {expanded && (
        <div className="ml-7">
          <DocumentsList
            key={treeRootItem.internalId}
            filters={filters}
            type={treeRootItem.internalId}
            searchTerm={searchTerm}
          />
        </div>
      )}
    </>
  );
};

export const DocumentClassifierTree = ({
  filters,
}: {
  filters: LocalCorpusFilterFilter[];
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 250);
  const [selectedUACProjectId] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    const newSelectedItems: string[] = [];
    filters.forEach((filter) => {
      if (filter.document_types?.length) {
        newSelectedItems.push(...filter.document_types);
      }
    });

    setSelectedItems(newSelectedItems);
  }, [filters]);

  return (
    <>
      <TextInput
        rounded
        name="corpus-filter-documents-search"
        placeholder="Search Documents..."
        value={searchTerm}
        onChange={(nextValue) => {
          setSearchTerm(nextValue);
        }}
      />
      <FormGroup className="mt-3">
        {CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS.map((treeRootItem) => (
          <DocumentClassifierTreeBranch
            key={treeRootItem.id}
            treeRootItem={treeRootItem}
            selectedItems={selectedItems}
            searchTerm={debouncedSearchTerm}
            selectedUACProjectId={selectedUACProjectId}
            filters={filters}
          />
        ))}
      </FormGroup>
    </>
  );
};
