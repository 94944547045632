import { ReactNode, useState, useCallback, useEffect } from "react";
import { ToastService } from "./ToastService";
import { Alert, AlertColor } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { cn, SLIDE_UP_AND_FADE_OUT_MAX_ANIMATION_MS } from "@trunk-tools/ui";
import { useTimeout } from "usehooks-ts";

export type ToastMessageMessage = string | ReactNode;

export interface ToastMessage {
  id: string;
  message: ToastMessageMessage;
  type: AlertColor;
}

export interface ToastContextType {
  showToast: (params: {
    message: ToastMessageMessage;
    type: AlertColor;
    closeTimeout?: number;
  }) => void;
}

const Toast = ({
  message,
  type,
  closeTimeout = 5000,
  onClose,
}: {
  message: ToastMessageMessage;
  type: AlertColor;
  closeTimeout?: number;
  onClose: () => void;
}) => {
  const [isClosing, setIsClosing] = useState(false);

  useTimeout(() => {
    setIsClosing(true);
  }, closeTimeout - SLIDE_UP_AND_FADE_OUT_MAX_ANIMATION_MS);

  useEffect(() => {
    setTimeout(onClose, closeTimeout);
  }, [closeTimeout, onClose]);

  return (
    <Alert
      onClose={onClose}
      severity={type}
      variant="filled"
      className={cn("w-full mt-3 shadow-lg", {
        "animate-slide-up-and-fade-in": !isClosing,
        "animate-slide-up-out-and-fade-out": isClosing,
      })}
    >
      {message}
    </Alert>
  );
};

export const ToastsContainer = () => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);

  const showToast = useCallback(
    ({
      message,
      type,
      closeTimeout,
    }: {
      message: ToastMessageMessage;
      type: AlertColor;
      closeTimeout?: number;
    }) => {
      setToasts((currentToasts) => [
        ...currentToasts,
        {
          id: crypto.randomUUID(),
          message,
          type,
          closeTimeout,
        },
      ]);
    },
    [],
  );

  useEffect(() => {
    ToastService.setCallback((params) => showToast(params));
  }, [showToast]);

  const handleClose = useCallback(({ toastId }: { toastId: string }) => {
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== toastId),
    );
  }, []);

  return (
    <div
      className="fixed bottom-0 right-0 p-3 max-w-[500px]"
      style={{
        zIndex: zIndex.snackbar,
      }}
    >
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          message={toast.message}
          type={toast.type}
          onClose={() => handleClose({ toastId: toast.id })}
        />
      ))}
    </div>
  );
};
