import useSWR from "swr";
import dayjs from "dayjs";
import useSWRMutation from "swr/mutation";
import { api } from "./helpers/APIClient";
import { useCurrentProject } from "./project.datahook";
import { useIdNavigate } from "@/hooks/useIdNavigate";

export const useUserConversations = () => {
  const { currentProject } = useCurrentProject();

  const fetch = async () => {
    const data = await api.getUserConversations({
      project_id: currentProject.id,
    });
    return data;
  };

  const { data: conversations, mutate } = useSWR(
    ["userConversations", currentProject.id],
    fetch,
    {
      suspense: true,
    },
  );

  return {
    conversations,
    refreshUserConversations: mutate,
  };
};

export const useCreateConversation = () => {
  const idNavigate = useIdNavigate();
  const { currentProject } = useCurrentProject();
  const { refreshUserConversations } = useUserConversations();

  const createConversation = async ({
    questionText,
    corpusFilterId,
  }: {
    questionText: string;
    corpusFilterId?: string;
  }) => {
    const result = await api.createConversation({
      project_id: currentProject.id,
      question_text: questionText,
      corpus_filter_id: corpusFilterId,
    });
    idNavigate(`/conversations/${result.id}`);
    refreshUserConversations();

    return result;
  };

  return {
    createConversation,
  };
};

export const useProjectConversationQuestion = () => {
  const { currentProject } = useCurrentProject();

  const askFollowUpQuestion = async ({
    conversationId,
    questionText,
  }: {
    conversationId: string;
    questionText: string;
  }) => {
    const result = await api.projectConversationQuestion({
      project_id: currentProject.id,
      conversation_id: conversationId,
      question_text: questionText,
    });

    return result;
  };

  const regenerateResponse = async ({
    conversationId,
    questionId,
  }: {
    conversationId: string;
    questionId: string;
  }) => {
    const result = await api.projectConversationQuestionRegenerate({
      project_id: currentProject.id,
      conversation_id: conversationId,
      question_id: questionId,
    });

    return result;
  };

  return {
    askFollowUpQuestion,
    regenerateResponse,
  };
};

export const useConversation = ({
  conversationId,
}: {
  conversationId?: string;
}) => {
  const fetch = async () => {
    if (!conversationId) {
      return null;
    }

    const data = await api.getConversation({
      conversation_id: conversationId,
    });
    return data;
  };

  const { data: conversation, mutate } = useSWR(
    ["conversation", conversationId],
    fetch,
    {
      suspense: true,
    },
  );

  return { conversation, refetchConversation: mutate };
};

export const useMaybeConversation = ({
  conversationId,
}: {
  conversationId: string | undefined;
}) => {
  const fetch = async () => {
    if (!conversationId) {
      return null;
    }

    const data = await api.getConversation({
      conversation_id: conversationId,
    });
    return data;
  };

  const { data: maybeConversation, mutate } = useSWR(
    ["maybeConversation", conversationId],
    fetch,
    {
      suspense: true,
    },
  );

  return { maybeConversation, refreshConversation: mutate };
};

export type ConversationsSection = {
  title: string;
  conversations: ReturnType<typeof useUserConversations>["conversations"];
};

const getSectionTitle = (date: Date): string => {
  const timeDiff = Date.now() - date.getTime();
  const days = timeDiff / (1000 * 60 * 60 * 24);

  if (days <= 7) {
    return "Last 7 Days";
  } else if (days <= 30) {
    return "Last 30 Days";
  } else {
    return dayjs(date).format("MMMM YYYY");
  }
};

/**
 * Conversations are assumed to be sorted already when they're retrieved from the backend.
 * So, in order to split the conversations into "sections" based on their creation date,
 * we just need iterate through the conversations linearly. When we encounter a date that
 * has a different "title", we increment our array index and add a new entry into the array.
 * Each entry in the array contains a (still sorted) array of conversations along with their
 * collective title to indicate what point in time they were asked.
 *
 * @returns An array of sections, each containing an array of conversations
 */
export const useConversationsSections = () => {
  const { conversations, refreshUserConversations } = useUserConversations();

  const conversationsSections: Array<ConversationsSection> = [];
  let currentTitle = "";
  let currentArrayIndex = -1;

  conversations.map((c) => {
    const sectionTitle = getSectionTitle(new Date(c.created_at));
    if (sectionTitle !== currentTitle) {
      currentTitle = sectionTitle;
      currentArrayIndex += 1;
    }

    if (conversationsSections.length <= currentArrayIndex) {
      conversationsSections.push({
        title: currentTitle,
        conversations: [],
      });
    }

    conversationsSections[currentArrayIndex].conversations.push(c);
  });

  return {
    conversationsSections,
    refreshUserConversations: refreshUserConversations,
  };
};

export const useSetCurrentCorpusFilterOnConversation = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const setCurrentCorpusFilterOnConversationFetch = async (
    key: unknown,
    {
      arg,
    }: {
      arg: {
        conversation_id: string;
        corpus_filter_id?: string;
      };
    },
  ) => {
    const result = await api.setCurrentCorpusFilterOnConversation({
      conversation_id: arg.conversation_id,
      corpus_filter_id: arg.corpus_filter_id,
    });

    return result;
  };

  return useSWRMutation(
    ["setCurrentCorpusFilterOnConversation"],
    setCurrentCorpusFilterOnConversationFetch,
    {
      onSuccess,
    },
  );
};

export const useSharedConversation = ({ token }: { token: string }) => {
  const fetch = async () => {
    const data = await api.getSharedConversation({
      token,
    });
    return data;
  };

  const { data: sharedConversation, mutate } = useSWR(
    ["sharedConversation", token],
    fetch,
    {
      suspense: true,
    },
  );

  return { sharedConversation, refreshSharedConversation: mutate };
};
